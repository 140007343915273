














































































































































































































































































































import StaffService from "@/services/StaffService";
import AuthService from "../services/AuthService";
import moment from "moment";
import "moment/locale/de";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import router from "../router/index";
import { BModal } from "bootstrap-vue";

@Component
export default class Staff extends Vue {
  private staff: any = {};
  private searchtext: string = "";
  private currentPage: number = 1;
  private pagesize: number = 10;
  private sortBy = "role,lastName,firstName";
  private sortDesc = false;
  private sortDirection = "asc";
  private selectedRow: any = null;
  private deleteEvent: any = null;
  @Ref("deleteError") readonly errorDialog!: BModal;

  valid(item: any) {
    return (
      this.state(item.address.street) &&
      this.state(item.address.zip) &&
      this.state(item.address.city) &&
      this.state(item.role) &&
      this.state(item.firstName) &&
      this.state(item.lastName) &&
      this.state(item.contact.phone)
    );
  }

  state(value: any) {
    if (!value) {
      return false;
    }

    return value.length > 0;
  }

  expandAdditionalInfo(row: any) {
    if (this.selectedRow) {
      this.selectedRow._showDetails = false;
    }
    row._showDetails = !row._showDetails;
    this.selectedRow = row;
  }

  add() {
    this.selectedRow = {
      address: {},
      contact: {},
      _showDetails: true,
    };
    this.staff.content.unshift(this.selectedRow);
  }

  sortChanged(data: any) {
    this.sortBy = data.sortBy;
    this.sortDesc = data.sortDesc;
    this.sortDirection = data.sortDesc ? "desc" : "asc";
    this.handlePageChange();
  }

  handlePageSizeChange() {
    this.currentPage = 1;
    this.reload();
  }

  @Watch("currentPage")
  handlePageChange() {
    this.reload();
  }

  @Watch("searchtext")
  search() {
    this.reload();
  }

  save(staff: any) {
    if (staff.uuid) {
      StaffService.update(staff.uuid, staff).then((response) => this.reload());
    } else {
      StaffService.create(staff).then((response) => this.reload());
    }
  }

  remove(staff: any) {
    StaffService.delete(staff.uuid)
      .then((response) => this.reload())
      .catch((err) => this.errorDialog.show());
  }

  isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  reload() {
    StaffService.findByTitle(
      this.searchtext,
      this.currentPage - 1,
      this.pagesize,
      `${this.sortBy},${this.sortDirection}`
    ).then((response) => {
      this.staff = response.data;
      this.selectedRow = null;
    });
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);
    this.reload();
  }
}
