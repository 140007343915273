import http from "../http-common";
import AuthService from "./AuthService";
import { DataService } from "./DataService";

class StaffService extends DataService {
    all() {
        return http.get(`${this.url}/all`,
          { headers: { Authorization: AuthService.token() } });
      }
    
}

export default new StaffService('/staff');